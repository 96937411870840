// Redux Action CONSTANTS
import * as actionTypes from "./actionTypes";

// Ajax Methods
import ajaxMethods from "../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../api/ajax-endpoints";

// Helpers
import { formatMifosDate } from "../../helpers/formatMifosDate";
import { appInsights } from "../../config/appInsights";

// (2.) Loans for Disbursement Action
export const getLoansForDisbursement = ({
  startDate,
  endDate,
  page,
  size,
  borrowerName,
  loanId,
  bvn,
  rcNumber,
  aggregatorId,
  loanRef,
}: any) => {
  return function (dispatch: any) {
    dispatch({
      type: actionTypes.GET_DISBURSEMENTS_START,
    });
    let queryParams = `Page=${page}&PageSize=${size}`;

    if (startDate && endDate) {
      queryParams = `${queryParams}&from_date=${startDate}&to_date=${endDate}`;
    }
    if (borrowerName) {
      queryParams = `${queryParams}&borrowername=${borrowerName}`;
    }
    if (bvn) {
      queryParams = `${queryParams}&bvn=${bvn}`;
    }
    if (rcNumber) {
      queryParams = `${queryParams}&rcnumber=${rcNumber}`;
    }
    if (aggregatorId) {
      queryParams = `${queryParams}&aggregatorId=${Number(aggregatorId)}`;
    }
    if (loanId) {
      queryParams = `${queryParams}&loanid=${Number(loanId)}`;
    }
    if (loanRef) {
      queryParams = `${queryParams}&loanreference=${loanRef}`;
    }

    let endpoint = ajaxEndpoints.LOANS_FOR_DISBURSEMENT;
    if (queryParams) {
      endpoint = `${endpoint}?${queryParams}`;
    }

    ajaxMethods
      .get(endpoint)
      .then((response) => {
        if (response) {
          dispatch({
            type: actionTypes.GET_DISBURSEMENTS_SUCCESS,
            payload: {
              records: [...response.data],
              disbursementCount: response.totalCount,
              size: response.pageSize,
              pageNumber: response.page,
              totalPage: response.totalPages,
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_DISBURSEMENTS_FAILURE,
            error: {
              errorMsg: "other error",
            },
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "loans.js",
          },
        });
        dispatch({
          type: actionTypes.GET_DISBURSEMENTS_FAILURE,
          error,
        });
      });
  };
};

export const removeDisbursedLoans = (loans: any) => {
  return {
    type: actionTypes.REMOVE_DISBURSED_LOANS,
    payload: {
      loans,
    },
  };
};
export const removeDeniedLoans = (loans: any) => {
  return {
    type: actionTypes.REMOVE_DENIED_LOANS,
    payload: {
      loans,
    },
  };
};
export const setDisbursementFilterStartDate = (value: any) => {
  return {
    type: actionTypes.SET_DISBURSEMENT_FILTER_START_DATE,
    payload: value,
  };
};
export const setDisbursementFilterEndDate = (value: any) => {
  return {
    type: actionTypes.SET_DISBURSEMENT_FILTER_END_DATE,
    payload: value,
  };
};
export const setDisbursementPageCount = (value: any) => {
  return {
    type: actionTypes.SET_DISBURSEMENT_COUNT,
    payload: value,
  };
};
export const setDisbursementPageNumber = (value: any) => {
  return {
    type: actionTypes.SET_DISBURSEMENT_PAGE_NUMBER,
    payload: value,
  };
};
export const setDisbursementPageSize = (value: any) => {
  return {
    type: actionTypes.SET_DISBURSEMENT_SIZE,
    payload: value,
  };
};
export const setDisbursementBorrowerName = (value: any) => {
  return {
    type: actionTypes.SET_DISBURSEMENT_BORROWER_NAME,
    payload: value,
  };
};
export const setDisbursementLoanId = (value: any) => {
  return {
    type: actionTypes.SET_DISBURSEMENT_LOAN_ID,
    payload: value,
  };
};
export const setDisbursementBvn = (value: any) => {
  return {
    type: actionTypes.SET_DISBURSEMENT_BVN,
    payload: value,
  };
};
export const setDisbursementRcNumber = (value: any) => {
  return {
    type: actionTypes.SET_DISBURSEMENT_RC_NUMBER,
    payload: value,
  };
};
export const setDisbursementAggregatorId = (value: any) => {
  return {
    type: actionTypes.SET_DISBURSEMENT_AGGREGATOR_ID,
    payload: value,
  };
};
export const setDisbursementLoanRef = (value: any) => {
  return {
    type: actionTypes.SET_DISBURSEMENT_LOAN_REF,
    payload: value,
  };
};

export const refetchPendingDisbursements = () => {
  return {
    type: actionTypes.REFETCH_LOANS
  }
}
// All Repayments
// (1.) Repayment Columns
export const repaymentColumns = [
  { label: "ID", field: "repayment_id" },
  { label: "REPAYMENT REF", field: "repayment_ref", sort: "asc" },
  { label: "PARTNER NAME", field: "borrower_full_name", sort: "asc" },
  { label: "AGGREGATOR", field: "aggregator_name", sort: "asc" },
  { label: "LOAN REF", field: "loan_ref", sort: "asc" },
  // { label: 'LOAN STATUS', field: 'loan_status', sort: 'asc' },
  { label: "REPAYMENT AMOUNT", field: "repayment_amount", sort: "asc" },
  { label: "REPAYMENT DATE", field: "repayment_date", sort: "asc" },
  { label: "CORE BANKING STATUS", field: "corebanking_status", sort: "asc" },
  {
    label: "REPAYMENT CHANNEL STATUS",
    field: "repayment_channel_status",
    sort: "asc",
  },
  { label: "REPAYMENT STATUS", field: "repayment_status", sort: "asc" },
  { label: "CURRENCY", field: "currency", sort: "asc" },
  // Manage Buttons
  { label: "MANAGE", field: "manage" },
];

// (2.) Repayment Action
export const getAllRepayments = (queryString?: any) => {
  return function (dispatch: any) {
    dispatch({
      type: actionTypes.GET_REPAYMENTS_START,
    });

    let endpoint = ajaxEndpoints.ALL_REPAYMENTS;
    if (queryString) {
      endpoint = `${endpoint}?${queryString}`;
    }

    ajaxMethods
      .get(endpoint)
      .then((response) => {
        if (response) {
          const reOrderedTableData = reOrderTableData(
            repaymentColumns,
            response.data
          );
          dispatch({
            type: actionTypes.GET_REPAYMENTS_SUCCESS,
            payload: {
              repaymentsTableData: reOrderedTableData,
              records: [...response.data],
              count: response.count,
              next_page: response.next_page,
              prev_page: response.prev_page,
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_REPAYMENTS_FAILURE,
            error: {
              errorMsg: "other error",
            },
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "loans.js",
          },
        });
        dispatch({
          type: actionTypes.GET_REPAYMENTS_FAILURE,
          error,
        });
      });
  };
};

// mifos repayment column
export const mifosRepaymentColumn = [
  { label: "#", field: "period", sort: "asc" },
  { label: "Days", field: "daysInPeriod", sort: "asc" },
  { label: "Date", field: "dueDate", sort: "asc" },
  { label: "Paid Date", field: "obligationsMetOnDate", sort: "asc" },
  { label: "Principal Due", field: "principalDue", sort: "asc" },
  {
    label: "Balance of Loan",
    field: "principalLoanBalanceOutstanding",
    sort: "asc",
  },
  { label: "Interest", field: "interestDue", sort: "asc" },
  { label: "Fees", field: "feeChargesDue", sort: "asc" },
  { label: "Penalties", field: "penaltyChargesDue", sort: "asc" },
  { label: "Due", field: "totalDueForPeriod", sort: "asc" },
  { label: "Paid", field: "totalPaidForPeriod", sort: "asc" },
  { label: "In advance", field: "totalPaidInAdvanceForPeriod", sort: "asc" },
  { label: "Late", field: "totalPaidLateForPeriod", sort: "asc" },
  { label: "Outstanding", field: "totalOutstandingForPeriod", sort: "asc" },
];

export const loanStatementColumn = [
  {
    label: "Value Date",
    field: "date",
    sort: "asc",
  },
  { label: "Activity", field: "transactionType", sort: "asc" },
  { label: "Amount", field: "amount", sort: "asc" },
  {
    label: "Principal",
    field: "principalPortion",
    sort: "asc",
  },
  { label: "Interest", field: "interestPortion", sort: "asc" },
  { label: "Charges", field: "feeChargesPortion", sort: "asc" },
  { label: "Penalties", field: "penaltyChargesPortion", sort: "asc" },
  { label: "Outstanding", field: "outstandingLoanBalance", sort: "asc" },
];

export const setLoanFilterStartDate = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_FILTER_START_DATE,
    payload: value,
  };
};
export const setLoanFilterEndDate = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_FILTER_END_DATE,
    payload: value,
  };
};
export const setLoanPageCount = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_COUNT,
    payload: value,
  };
};
export const setLoanPageNumber = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_PAGE_NUMBER,
    payload: value,
  };
};
export const setLoanPageSize = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_SIZE,
    payload: value,
  };
};

export const setLoanDisbursedValue = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_IS_DISBURSED,
    payload: value,
  };
};
export const setLoanApprovedValue = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_IS_APPROVED,
    payload: value,
  };
};

export const setLoanStatusValue = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_STATUS,
    payload: value,
  };
};
export const setLoanStartDateValue = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_START_DATE,
    payload: value,
  };
};
export const setLoanEndDateValue = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_END_DATE,
    payload: value,
  };
};
export const setLoanOnMifos = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_ON_MIFOS,
    payload: value,
  };
};
export const setBorrowerName = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_BORROWER_NAME,
    payload: value,
  };
};
export const setLoanId = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_ID,
    payload: value,
  };
};
export const setBvn = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_BVN,
    payload: value,
  };
};
export const setRcNumber = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_RC_NUMBER,
    payload: value,
  };
};
export const setAggregatorId = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_AGGREGATOR_ID,
    payload: value,
  };
};
export const setLoanRef = (value: any) => {
  return {
    type: actionTypes.SET_LOAN_REFERENCE,
    payload: value,
  };
};
// (2.) Loan Action
export const getAllLoans = ({
  startDate,
  endDate,
  page,
  size,
  disbursed,
  approved,
  status,
  disbursement_from,
  disbursement_to,
  IsMigratedtoCBA,
  borrowerName,
  loanId,
  bvn,
  rcNumber,
  aggregatorId,
  loanRef,
}: any) => {
  return function (dispatch: any) {
    dispatch({
      type: actionTypes.GET_LOANS_START,
    });
    let queryParams = `Page=${page}&PageSize=${size}`;

    if (startDate && endDate) {
      queryParams = `${queryParams}&from_date=${startDate}&to_date=${endDate}`;
    }
    if (
      disbursed === "true" ||
      disbursed === true ||
      disbursed === "false" ||
      disbursed === false
    ) {
      // Convert to boolean if it is a string
      queryParams = `${queryParams}&disbursed=${JSON.parse(disbursed)}`;
    }
    if (
      approved === "true" ||
      approved === true ||
      approved === "false" ||
      approved === false
    ) {
      // Convert to boolean if it is a string
      queryParams = `${queryParams}&approved=${JSON.parse(approved)}`;
    }
    if (
      IsMigratedtoCBA === "true" ||
      IsMigratedtoCBA === true ||
      IsMigratedtoCBA === "false" ||
      IsMigratedtoCBA === false
    ) {
      // Convert to boolean if it is a string
      queryParams = `${queryParams}&IsMigratedtoCBA=${JSON.parse(
        IsMigratedtoCBA
      )}`;
    }
    if (status) {
      queryParams = `${queryParams}&status=${status}`;
    }
    if (disbursement_from) {
      queryParams = `${queryParams}&disbursement_from=${disbursement_from}`;
    }
    if (disbursement_to) {
      queryParams = `${queryParams}&disbursement_to=${disbursement_to}`;
    }
    if (borrowerName) {
      queryParams = `${queryParams}&borrowername=${borrowerName}`;
    }
    if (bvn) {
      queryParams = `${queryParams}&bvn=${bvn}`;
    }
    if (rcNumber) {
      queryParams = `${queryParams}&rcnumber=${rcNumber}`;
    }
    if (aggregatorId) {
      queryParams = `${queryParams}&aggregatorId=${Number(aggregatorId)}`;
    }
    if (loanId) {
      queryParams = `${queryParams}&loanid=${Number(loanId)}`;
    }
    if (loanRef) {
      queryParams = `${queryParams}&loanreference=${loanRef}`;
    }

    let endpoint = ajaxEndpoints.ALL_LOANS;
    if (queryParams) {
      endpoint = `${endpoint}?${queryParams}`;
    }
    ajaxMethods
      .get(endpoint)
      .then((response) => {
        if (response) {
          dispatch({
            type: actionTypes.GET_LOANS_SUCCESS,
            payload: {
              records: [...response.data],
              count: response.totalCount,
              size: response.pageSize,
              pageNumber: response.page,
              totalPage: response.totalPages,
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_LOANS_FAILURE,
            error: {
              errorMsg: "other error",
            },
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "loans.js",
          },
        });
        dispatch({
          type: actionTypes.GET_LOANS_FAILURE,
          error,
        });
      });
  };
};
export const displayRepaymentColumns = [
  { label: "Transfered amount", field: "trans_amount", sort: "asc" },
  { label: "Full Name", field: "borrower_full_name", sort: "asc" },
  { label: "Repayment Ref", field: "repayment_ref", sort: "asc" },
  // { label: "Pub Date", field: "pub_date", sort: "asc" },
  { label: "Oustanding", field: "loan_outstanding", sort: "asc" },
  { label: "Due Date", field: "loan_due_date", sort: "asc" },
];
export const displayLoanScheduleColumns = [
  { label: "Pay Date", field: "pay_date", sort: "asc" },
  { label: "Amount", field: "repay_amount", sort: "asc" },
];

// 1. loan schedule columns
export const disbursedAccountColumns = [
  { label: "S/N", field: "id", sort: "asc" },
  { label: "Channel Name", field: "channel_name", sort: "asc" },
  { label: "Bank Name", field: "bank_name", sort: "asc" },
  { label: "Account Name", field: "account_name", sort: "asc" },
  { label: "Account Number", field: "account_number", sort: "asc" },
  { label: "Currency", field: "currency", sort: "asc" },
  { label: "Wallet ID", field: "wallet_id", sort: "asc" },
  { label: "Wallet Provider", field: "wallet_provider", sort: "asc" },
];
export const RepaymentAccountColumns = [
  { label: "S/N", field: "id", sort: "asc" },
  { label: "Channel Name", field: "channel_name", sort: "asc" },
  { label: "Bank Name", field: "bank_name", sort: "asc" },
  { label: "Account Name", field: "account_name", sort: "asc" },
  { label: "Account Number", field: "account_number", sort: "asc" },
];
export const LiquidationAccountColumns = [
  { label: "S/N", field: "id", sort: "asc" },
  // { label: "Channel Name", field: "channel_name", sort: "asc" },
  // { label: "Bank Name", field: "bank_name", sort: "asc" },
  { label: "Account Name", field: "account_name", sort: "asc" },
  { label: "Account Number", field: "account_number", sort: "asc" },
];

//  Reorder an Array/Rows of records from the API
//  @dataTable {Object}
export const reOrderTableData = (columns: any, records: any) => {
  let newRows = [] as any[];

  if (records) {
    if (records.length > 0) {
      newRows = records.map((row: any) => {
        let newRow = {} as any;
        columns.forEach((column: any) => {
          if (column.field === "pub_date") {
            newRow[column.field] = row[column.field].substring(0, 10);
            return;
          } else {
            newRow[column.field] = row.hasOwnProperty(column.field)
              ? column.field === "dueDate" ||
                column.field === "obligationsMetOnDate"
                ? formatMifosDate(row[column.field])
                : row[column.field]
              : null;
          }
        });
        return newRow;
      });
    } else {
      newRows = [];
    }
  }
  return {
    columns,
    rows: newRows,
  };
};

//  Reorder an Array/Rows of loan repayment records from the API
export const reOrderRepaymentRows = (
  tableColumns: any,
  responseDataRecords: any
) => {
  let newRows = [];

  if (responseDataRecords) {
    if (responseDataRecords.length > 0) {
      newRows = responseDataRecords.map((row: any, index: number) => {
        let newRow = {} as any;
        tableColumns.forEach((column: any) => {
          if (column.field === "id") {
            newRow[column.field] = index + 1;
            return;
          } else if (column.field === "pub_date") {
            newRow[column.field] = row[column.field].substring(0, 10);
            return;
          } else if (column.field === "loan_due_date") {
            newRow[column.field] = row[column.field].substring(0, 10);
            return;
          }
          newRow[column.field] = row[column.field] ? row[column.field] : null;
        });
        return newRow;
      });
    } else {
      newRows = [];
    }
  }

  return {
    columns: tableColumns,
    rows: newRows,
  };
};
